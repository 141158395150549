import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Disclosure, Transition } from '@headlessui/react'


import parse from "html-react-parser";


import {
  CheckIcon, LockClosedIcon,
  CashIcon,
  UserGroupIcon,
  BanIcon,
  ShareIcon,
  FilterIcon,
  ScaleIcon,
  ExternalLinkIcon,
  InformationCircleIcon
} from '@heroicons/react/solid'

import {


  ChevronDownIcon
} from '@heroicons/react/outline'

import Layout from "../components/layout"
import Seo from "../components/seo"
import Cta from "../components/cta"

import Launch from "../images/launch.svg"
import Growth from "../images/growth.svg"
import Moon from "../images/moon.svg"

import TheGoodness from "../images/the-goodness.svg"
import Hepburne from "../images/hepburne.svg"
import Spark from "../images/spark.svg"
import Lloyd from "../images/lloyd.svg"

import CoinGecko from "../images/coingecko.svg"
import CoinMarketCap from "../images/coinmarketcap.svg"
import BSCScan from "../images/bscscan.svg"

var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')




dayjs.extend(utc)

const features = [
  {
    name: 'ZERO Team Tokens',
    description: '',
    icon: BanIcon,
  },
  {
    name: 'Experienced & Professional Team',
    description: '',
    icon: UserGroupIcon,
  },
  {
    name: '10% Bitcoin Distribution',
    description: 'A minimum of 200k tokens is required to be held to qualify for dividend distribution',
    icon: CashIcon,
  },
  {
    name: '5% Marketing Wallet',
    description: '',
    icon: ShareIcon,
  },
  {
    name: 'Anti-Dump',
    description: '',
    icon: FilterIcon,
  },
  {
    name: 'Anti-Whale',
    description: '',
    icon: ScaleIcon,
  },

]



const team = [
  {
    name: 'The Goodness',
    role: 'CEO',
    imageUrl: TheGoodness,
  },
  {
    name: 'Hepburne',
    role: 'Lead Dev',
    imageUrl: Hepburne,
  },
  {
    name: 'Spark',
    role: 'Marketing Specialist',
    imageUrl: Spark,
  },
  {
    name: 'Lloyd',
    role: 'Socials and Partnerships',
    imageUrl: Lloyd,
  },
]

const faqs = [
  {
    question: "What is DogeBTC?",
    answer:
      "A NEW ERA IN THE DOGE HYPE! Earn BTC while Holding DogeBTC. Yes, our distribution is BITCOIN! 10% will be re-distributed among our community! 💰",
  },
  {
    question: "How do I buy DogeBTC?",
    answer:
      "You can buy the tokens on <a href='https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb3fa62ed37e885e3f6b98f1bf9eaa724bb9aff39' target='_blank' class='text-yellow-700 underline'>PancakeSwap</a>. Make sure you click the Settings icon on PancakeSwap and set the Slippage Tolerance to <strong>15%</strong>",
  },
  {
    question: "Unable to buy DogeBTC on Pancakeswap?",
    answer:
      "Click the Settings icon on PancakeSwap and set the Slippage Tolerance to 15%. If it still doesn't work, contact us. One of our team, or someone from the community will be happy to help!",
  },
  {
    question: "Unable to sell DogeBTC on Pancakeswap?",
    answer:
      "Click the Settings icon on PancakeSwap and set the Slippage Tolerance to 18%. If it still doesn't work, contact us. One of our team, or someone from the community will be happy to help!",
  },
  {
    question: "How do I get the BTC rewards?",
    answer:
      "You'll automatically receive $BTCB tokens when you hold. Sit back, relax and watch your wallet grow! A minimum of 200k tokens is required to be held to qualify for dividend distribution",
  },
  {
    question: "How can I contact you?",
    answer:
      "You may contact us on Telegram or email us directly at contact@dogebtc.finance",
  },
]


const roadmap = [
  [
    {
      content: 'Website Launch',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'Pre-Sale Launch',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'Multi-Channel Marketing',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'Advertising Campaigns',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: '10,000 Telegram Members',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'DessertSwap Audit',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'PancakeSwap Listing',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: '1,000+ Holders',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: '$1 Million+ Market Cap',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'CoinGecko Listing',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
  ],
  [
    {
      content: 'CoinMarketCap Listing',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'Dividend Dashboard with total & user statistics',
      icon: CheckIcon,
      iconBackground: 'bg-yellow-400',
    },
    {
      content: 'WhitePaper Release',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: '$10 Million+ Market Cap',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: '10,000+ Holders',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: '25,000 Telegram Members',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: 'Blockfolio Listing',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: 'Trustwallet Logo',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
  ],

  [
    {
      content: '$50 Million+ Market Cap',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: '30,000+ Holders',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: '50,000 Telegram Members',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: 'NFT Platform',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
    {
      content: 'Exchanges Listing',
      icon: null,
      iconBackground: 'bg-gray-200',
    },
  ],


]

const quarters = [
  {
    quarter: 'Launch',
    icon: Launch
  },
  {
    quarter: 'Growth',
    icon: Growth
  },
  {
    quarter: 'Moon',
    icon: Moon
  }
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


const IndexPage = () => {
  // let totalBtcb
  // const [totalLoaded, setTotalLoaded] = useState(false)
  // const [total, setTotal] = useState([])
  // const [btcbPriceLoaded, setBtcbPriceLoaded] = useState(false)
  // const [btcbPrice, setBtcbPrice] = useState([])
  // useEffect(() => {
  //   fetch(`https://api.dogebtc.finance/get-total`)
  //     .then(response => {
  //       return response.json()
  //     }).then(json => {
  //       setTotal(json)
  //       totalBtcb = json;
  //       setTotalLoaded(true)
  //       fetch(`https://api.dogebtc.finance/get-btcb-price`)
  //         .then(response => {
  //           return response.json()
  //         }).then(json => {
  //           setBtcbPrice(parseInt(parseFloat(totalBtcb) * parseFloat(json), 10).toLocaleString())
  //           setBtcbPriceLoaded(true)
  //         })
  //     })


  // }, [])
  return (
    <Layout>
      <Seo

        title="Earn BTC while Holding DogeBTC"

      />




      <div className="relative bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-0" aria-hidden="true">
          <svg
            className="absolute top-0 left-1/2 transform translate-x-64 -translate-y-8"
            width={640}
            height={784}
            fill="none"
            viewBox="0 0 640 784"
          >
            <defs>
              <pattern
                id="9ebea6f4-a1f5-4d96-8c4e-4c2abf658047"
                x={118}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect y={72} width={640} height={640} className="text-gray-50" fill="currentColor" />
            <rect x={118} width={404} height={784} fill="url(#9ebea6f4-a1f5-4d96-8c4e-4c2abf658047)" />
          </svg>
        </div>

        <div className="relative pt-6 pb-20 sm:pb-24 lg:pb-28">
          <main className="mt-12 sm:mt-18 lg:mt-20 container">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8 items-center">
              <div className="text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left mb-3 md:mb-6">
                <p className="text-base md:text-lg lg:text-xl font-medium tracking-wider text-yellow-500 uppercase mb-3 md:mb-4">A new era in the Doge Hype!</p>

                <h1 className="text-4xl md:text-6xl xl:text-7xl font-display font-semibold mb-4 lg:mb-6 xl:mb-8 max-w-4xl">
                  Earn BTC while Holding Doge<span className="text-yellow-500">BTC</span></h1>
                <p className=" text-lg md:text-lg lg:text-xl xl:text-2xl xl:leading-relaxed mb-4 lg:mb-6 xl:mb-7 max-w-2xl text-gray-700">Yes, our distribution is BITCOIN! 10% will be re-distributed among our community! 💰</p>
                {/* <div className="bg-gray-100 rounded-lg py-4 px-5 md:py-5 md:px-7 inline-block mb-4 lg:mb-6 xl:mb-7">
                  <h5 className="text-xs md:text-sm font-medium tracking-wider text-yellow-500 uppercase mb-0.5">Total Distributed</h5>
                  <div className="text-2xl md:text-3xl text-gray-900 font-medium flex items-center mt-1 leading-tight md:leading-tight">
                    {totalLoaded
                      ?
                      <Transition
                        show={totalLoaded}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                      >
                        <span>{total}</span>
                      </Transition>

                      :
                      <span className="bg-gray-300 rounded-lg animate-pulse"><span className="opacity-0">0.000000</span></span>}
                    <span className="text-gray-700 font-normal text-base md:text-xl ml-2"> $BTCB</span>
                  </div>
                  <div className="text-xs md:text-sm text-gray-500 inline-flex items-center mx-auto lg:mx-0 leading-tight md:leading-tight">
                  {btcbPriceLoaded
                      ?
                      <Transition
                        show={btcbPriceLoaded}
                        enter="transition-opacity duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                      >
                        <span>~ ${btcbPrice}</span>
                      </Transition>

                      :
                      <span className="bg-gray-300 rounded-lg animate-pulse"><span className="opacity-0">~ $0000000</span></span>}
                      <span className="ml-1"> USD</span>
                  </div>

                </div> */}
                <div className="-ml-1.5 -mr-1.5 md:-ml-2 md:-mr-2 flex items-center flex-wrap justify-center lg:justify-start">
                  <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb3fa62ed37e885e3f6b98f1bf9eaa724bb9aff39" target="_blank" className="flex items-center flex-shrink-0 text-gray-900 bg-yellow-400 transform hover:bg-yellow-500  px-4 py-3 lg:px-8 lg:py-5 mt-2 text-sm md:text-base lg:text-lg font-medium rounded-lg mx-1.5 sm:mx-2 focus:outline-none leading-tight space-x-1 lg:space-x-1.5 shadow-sm hover:shadow-md">
                    <span>Buy DogeBTC</span>
                  </a>
                  <a href="https://poocoin.app/tokens/0xb3fa62eD37e885e3F6b98F1Bf9EAa724BB9afF39" target="_blank" className="flex items-center flex-shrink-0 text-gray-900 bg-white transform border border-gray-300 hover:border-gray-400  px-4 py-3 lg:px-8 lg:py-5 mt-2 text-sm md:text-base lg:text-lg font-medium rounded-lg mx-1.5 sm:mx-2 focus:outline-none leading-tight space-x-1 lg:space-x-1.5 shadow-sm hover:shadow-md">
                    <span>View Charts</span>
                  </a>
                </div>
                <div className="rounded-md bg-yellow-50 p-4 mt-4 md:mt-6 mx-auto lg:mx-0 inline-block">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <InformationCircleIcon className="h-5 w-5 text-yellow-500" aria-hidden="true" />
                    </div>
                    <div className="ml-3 flex-1 md:flex md:justify-between">
                      <p className="text-sm text-yellow-700">Use the following slippage - <strong>15% to Buy</strong>, <strong>18% to Sell</strong></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
                <svg
                  className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
                  width={640}
                  height={784}
                  fill="none"
                  viewBox="0 0 640 784"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
                      x={118}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect x={118} width={404} height={784} fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)" />
                </svg>
                <div className="relative mx-auto w-56 lg:w-full lg:max-w-md">
                  <StaticImage
                    src="../images/hero.png"
                    className="w-full block"
                    width={500}
                    alt=""
                    loading="eager"
                    objectFit="contain"
                  />
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="bg-white">
        <div className="container pt-12 pb-2 lg:pt-2 lg:pb-10">
          <div className="grid grid-cols-2 gap-8  sm:grid-cols-6 lg:grid-cols-3">
            <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
              <a href="https://www.coingecko.com/en/coins/dogebtc" target="_blank">
                <img className="h-8 md:h-11 lg:h-12 filter grayscale hover:filter-none transition-all opacity-60 hover:opacity-100" src={CoinGecko} alt="CoinGecko" />
              </a>

            </div>
            <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
              <a href="https://coinmarketcap.com/currencies/dogebtc/" target="_blank">
                <img className="h-7 md:h-10 lg:h-11 filter grayscale hover:filter-none opacity-40 hover:opacity-100 transition-all" src={CoinMarketCap} alt="CoinMarketCap" />
              </a>
            </div>
            <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
              <a href="https://bscscan.com/token/0xb3fa62ed37e885e3f6b98f1bf9eaa724bb9aff39" target="_blank">
                <img className="h-7 md:h-10 lg:h-11 filter grayscale hover:filter-none opacity-50 hover:opacity-100 transition-all" src={BSCScan} alt="BSCScan" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white pt-16 lg:py-24 container">
        <div className="pb-16 bg-gradient-to-tr from-yellow-400 to-yellow-500 lg:pb-0 lg:z-10 lg:relative rounded-lg">
          <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="relative lg:-my-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-3xl pt-12 lg:pt-0 sm:px-6 lg:p-0 lg:h-full">
                <div className="lg:h-full text-center">
                  <StaticImage
                    src="../images/cool-doge-cz.png"
                    className="object-contain w-44 mx-auto lg:h-full lg:w-full"
                    width={500}
                    alt=""
                    objectFit="contain"
                  />

                </div>
              </div>
            </div>
            <div className="mt-6 lg:m-0 lg:col-span-2 lg:pl-8">
              <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:px-0 lg:py-20 lg:max-w-none">
                <blockquote>
                  <div>
                    <svg
                      className="h-12 w-12 text-yellow-900 opacity-25 mx-auto lg:mx-0"
                      fill="currentColor"
                      viewBox="0 0 32 32"
                      aria-hidden="true"
                    >
                      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                    </svg>
                    <p className="mt-6 text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-medium text-gray-900 mx-auto text-center lg:mx-0 lg:text-left">
                      Inflation makes you poorer. #Bitcoin has no inflation.
                    </p>
                  </div>
                  <footer className="mt-6">
                    <p className="text-base md:text-lg lg:text-xl font-medium text-gray-900 text-opacity-80 mx-auto text-center lg:mx-0 lg:text-left">CZ Binance</p>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-white overflow-hidden" id="features">
        <div className="relative container py-16 sm:py-24 lg:py-32">
          <svg
            className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={784} fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)" />
          </svg>

          <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
            <div className="lg:col-span-1 text-center lg:text-left">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-yellow-500 uppercase">Features</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                Why invest in DogeBTC?
              </p>
              <a href="https://tinyurl.com/dogebtcaudit" target="_blank" className="rounded-lg bg-white border border-green-500 hover:bg-green-50 inline-flex items-center py-3 px-5 mt-6">
                <LockClosedIcon className="w-6 h-6 flex-shrink-0 text-green-600" />
                <div className="text-left text-gray-800 ml-2.5 flex items-center space-x-4">
                  <h5 className="font-medium text-base md:text-lg text-green-600">DessertSwap Audit</h5>
                </div>
                <ExternalLinkIcon className="w-4 h-4 ml-2 flex-shrink-0 text-gray-500" />
              </a>
            </div>
            <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-2">
              {features.map((feature) => (
                <div key={feature.name} className="text-center lg:text-left">
                  <dt>
                    <div className="flex items-center justify-center h-14 w-14 md:h-16 md:w-16 rounded-full bg-yellow-100 text-yellow-500 mx-auto lg:mx-0">
                      <feature.icon className="h-6 w-6 md:h-8 md:w-8" aria-hidden="true" />
                    </div>
                    <p className="mt-5 text-lg md:text-xl font-medium text-gray-900">{feature.name}</p>
                  </dt>
                  {feature.description ? (
                    <dd className="mt-1 text-xs md:text-sm text-gray-500">{feature.description}</dd>
                  ) : null}
                  {/* <dd className="mt-2 text-base md:text-lg text-gray-500">{feature.description}</dd> */}
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="relative py-16 sm:py-24 lg:py-32 bg-gray-50 z-20 pb-4 sm:pb-6 lg:pb-8" id="roadmap">
        <div className="container">
          <div className="space-y-12 md:space-y-16">
            <div className="text-center">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-yellow-500 uppercase">Roadmap</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                See Our Progress
              </p>
              <p className="mt-5 max-w-prose mx-auto text-base md:text-lg lg:text-xl text-gray-700">
                We have many great ideas that we're excited to bring to the holders of DogeBTC. Got any ideas of your own? We love community input - let us know!
              </p>
            </div>
            <ul className="mx-auto space-y-6 sm:grid md:grid-cols-2 sm:gap-8 sm:space-y-0 xl:grid-cols-3 text-gray-900">
              {roadmap.map((quarter, index) => (
                <li className="rounded-lg bg-white shadow-md p-8 sm:p-12 lg:p-14" key={index}>
                  <div className="flex items-center justify-center h-14 w-14 md:h-16 md:w-16 rounded-full bg-yellow-100 text-yellow-500">
                    <img className="h-6 w-6 md:h-8 md:w-8" src={quarters[index].icon} aria-hidden="true" />
                  </div>
                  <h3 className="text-2xl lg:text-3xl font-medium mb-8 mt-5">{quarters[index].quarter}</h3>
                  <div className="flow-root">
                    <div className="-mb-8">
                      {quarter.map((item, index) => (
                        <div key={index}>
                          <div className="relative pb-8">
                            {index !== quarter.length - 1 ? (
                              <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                            ) : null}
                            <div className="relative flex space-x-3">
                              <div>
                                <span
                                  className={classNames(
                                    item.iconBackground,
                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                  )}
                                >
                                  {item.icon !== null ? (
                                    <item.icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
                                  ) : null}

                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div>
                                  <p className="text-sm lg:text-base text-gray-800">
                                    {item.content}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                </li>
              ))}


            </ul>
          </div>
        </div>
      </div>
      <div className="relative py-16 sm:py-24 lg:py-32 bg-gray-50 z-20" id="team">
        <div className="container">
          <div className="space-y-12 md:space-y-16">
            <div className="text-center">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-yellow-500 uppercase">Team</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                Meet Our Core Team
              </p>
              <p className="mt-5 max-w-prose mx-auto text-base md:text-lg lg:text-xl text-gray-700">
                We're a passionate group of crypto-enthusiasts, dedicated to bringing the best token to your wallet
              </p>
            </div>
            <ul className="mx-auto space-y-12 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 xl:grid-cols-4 text-center text-gray-900">
              {team.map((team) => (
                <li>
                  <div className="space-y-6">
                    <img src={team.imageUrl} className="mx-auto h-40 w-40 xl:w-56 xl:h-56" />
                    <div className="space-y-2">
                      <div className="leading-6 space-y-1">
                        <h3 className="text-2xl md:text-3xl font-medium">{team.name}</h3>
                        <p className="text-yellow-500 text0base md:text-lg">{team.role}</p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="relative pt-16 sm:pt-24 lg:pt-32 bg-white z-20" id="faqs">
        <div className="container max-w-4xl">
          <div className="">
            <div className="text-center">
              <h2 className="text-base lg:text-lg font-medium tracking-wider text-yellow-500 uppercase">FAQs</h2>
              <p className="mt-2 text-3xl font-bold text-gray-900 tracking-tight sm:text-4xl lg:text-5xl">
                Got any questions?
              </p>
            </div>
            <dl className="mt-8 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq) => (
                <Disclosure as="div" key={faq.question} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-700">
                          <span className="font-medium text-gray-900 text-lg md:text-xl">{faq.question}</span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base md:text-lg text-gray-700">{parse(faq.answer)}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <Cta />
    </Layout>
  )
}

export default IndexPage

