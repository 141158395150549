import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import {
  Twitter,
  Reddit,
  Instagram,
  Telegram,
  Discord
} from '@icons-pack/react-simple-icons';

const socials = [
  {
    name: 'Twitter',
    href: 'https://twitter.com/TheRealDogeBTC',
    icon: Twitter,
  },
  {
    name: 'Reddit',
    href: 'https://www.reddit.com/r/TheRealDogeBTC/',
    icon: Reddit,
  },
  {
    name: 'Instagram',
    href: 'https://instagram.com/dogebtc.finance',
    icon: Instagram,
  },
  {
    name: 'Telegram',
    href: 'https://t.me/TheRealDogeBTC',
    icon: Telegram,
  },
  // {
  //   name: 'Discord',
  //   href: 'https://discord.gg/KxaHpHbQy4',
  //   icon: Discord,
  // },
]



export default function Cta() {
  return (
    <div>
      <div className="relative py-16 sm:py-24 lg:py-32 bg-white z-20" id="cta">
        <div className="container">
          <div className="bg-gradient-to-tr from-yellow-400 to-yellow-500 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4 items-center">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl lg:text-5xl">
                  <span className="block">Buy DogeBTC & Join Our Community</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-gray-700 lg:text-xl">
                  Join the most exciting & rewarding token on the Binance Smart Chain!
                </p>
                <a
                  href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb3fa62ed37e885e3f6b98f1bf9eaa724bb9aff39" target="_blank"
                  className="mt-8 bg-white border border-transparent rounded-lg shadow px-5 py-3 inline-flex items-center text-base font-medium text-gray-900 hover:bg-yellow-50"
                >
                  Buy DogeBTC
                </a>
                <div className="flex space-x-5 mt-10">
                  {socials.map((item) => (
                    <a key={item.name} href={item.href} className="text-white hover:text-white">
                      <span className="sr-only">{item.name}</span>
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="-mt-6 aspect-w-5 aspect-h-3 md:aspect-w-2 md:aspect-h-1">
              <StaticImage
                src="../images/cta-dogebtc.png"
                className="transform translate-x-6 translate-y-6 rounded-md object-cover object-left-top sm:translate-x-16 lg:translate-y-20"
                width={1000}
                alt=""
                objectFit="contain"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}